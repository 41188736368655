<script type="ts">
	import Twitter from '$icons/twitter.svg';
</script>

<div class="share">
	<div class="grid content">
		<h3>More Patterns?</h3>
		<p>
			Comments? Suggestions? Or maybe you just want to be kept in the loop when the next post is
			published? Get in touch on Twitter:
		</p>
		<a class="twitter-button" href="https://twitter.com/@OnWritingCode" target="_blank">
			<div class="icon"><Twitter /></div>
			<span><span>On</span><span>Writing</span><span>Code</span></span>
		</a>
	</div>
</div>

<div class="bottom">
	<span>(END)</span>
</div>

<style lang="scss">
	.twitter-button {
		display: flex;
		align-items: center;
		border-radius: 20px;
		padding: 10px 24px;
		gap: 6px;
		width: max-content;
		margin-top: 30px;

		.icon {
			width: 1em;
			height: 1em;
			fill: hsl(203, 89%, 43%);
		}

		.at {
			font-weight: 700;
			// color: hsl(203, 29%, 92%);
			position: relative;
			font-size: 0.75em;
			top: -2px;
		}

		span span {
			margin-left: 1px;
		}

		text-decoration: none;
		background-color: white;
		color: hsl(203, 89%, 34%);
		font-size: 0.875rem;
		font-weight: 500;
		letter-spacing: 0.3px;

		&:hover {
			background-color: hsl(203, 89%, 53%);
			color: white;
			transition: all 0.2s ease-in-out;

			.icon {
				transition: all 0.2s ease-in-out;
				fill: white;
			}
		}
	}

	.share {
		--offsetB: 20%;
		min-height: 300px;
		@include svg_border_top(var(--bgc-accent-higher), 40px);
		background-color: var(--bgc-accent-higher);

		padding-bottom: 160px;
		margin-top: 160px;

		p {
			font-style: italic;
		}
	}

	.bottom {
		--offsetB: 5%;
		@include svg_border_top(var(--bgc-contrast-10), 30px);
		margin-top: -10px;
		background-color: var(--bgc-contrast-10);
		color: var(--code-text);
		text-align: center;
		padding-bottom: 40px;

		span {
			font-family: var(--font-code);
			color: var(--bgc-accent);
			padding: 1px;
			font-size: 0.8em;
		}
	}
</style>
