<script type="ts">
	import Twitter from '$icons/twitter.svg';
</script>

<div class="header">
	<div class="grid--inner">
		<div class="header__inner">
			<a class="home" href="/">On Writing Code</a>
			<a
				class="icon twitter"
				href="https://twitter.com/OnWritingCode"
				target="_blank"
				aria-label="On Writing Code on Twitter"
			>
				<Twitter />
			</a>
		</div>
	</div>
	<slot />
</div>

<style lang="scss">
	.home {
		font-size: 1.4rem;
	}
	.header {
		margin-bottom: 8vh;
		background-color: var(--bgc-accent-higher);
		@include svg-border-bottom(var(--bgc-accent-higher), 30px);
	}
	.header__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	a {
		font-family: var(--font-heading);
		padding: calc(1rem + 3vh) 0;
		color: var(--text-color);
		text-decoration: none;
		grid-area: wide;
		&:hover {
			color: var(--text-color-alt);
		}
	}
	.icon {
		:global(svg) {
			width: 20px;
			fill: var(--text-accent-high);
		}
		&:hover :global(svg) {
			fill: var(--text-color-alt);
		}
	}
</style>
